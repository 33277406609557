import React from 'react';
import { graphql, Link } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import _get from 'lodash/get';

import Layout from '@components/Layout';
import Images from '@components/Images';
import ArticleContent from '@components/ArticleContent';

import { extractGraphqlData } from '@utils/extractors';
import { linkResolver } from '@utils/Prismic/linkResolver';
import { formatDate } from '@utils/formatters';
import { TArticleList } from '@pages/news';
import { ENUM_STATIC_MENU_LINKS } from '@utils/types';

interface IArticleTemplateProps {
  data: {
    prismic: {
      allNews_articles: {
        edges: TArticleList[];
      };
    };
  };
}

const ArticleTemplate: React.FC<IArticleTemplateProps> = ({ data }) => {
  const doc = extractGraphqlData(data, 'allNews_articles');
  if (!doc) return null;
  const { page_title, page_description, canonical_tag, page_og_image } = doc.node.seo[0];
  const headLine: string = RichText.asText(doc.node.title);
  const images = [];

  for(var index = 0; index < doc.node.article_content.length; index += 1) {
    images.push({image: doc.node.article_content[0].image_body})
  }

  return (
    <Layout
      title={page_title ? RichText.asText(page_title) : headLine}
      description={page_description && RichText.asText(page_description)}
      secondLevelPage
      seo={{
        path: `${ENUM_STATIC_MENU_LINKS.NEWS}/${doc.node._meta.uid}`,
        canonical: canonical_tag && RichText.asText(canonical_tag),
        image: page_og_image ? page_og_image : doc.node.article_content[0].image_body.url,
      }}
      article={{
        author: doc.node.author ? RichText.asText(doc.node.author) : '',
        headLine,
        publicationDate: doc.node.publication_date,
      }}
      isNotMenu
      path="news"
    >
      <div className="article">
        <div className="article__content">
          <div className="anim-title">
            <h1>{headLine}</h1>
            <div className="article__info">
              <span className="article__category">{doc.node.category}</span>
              <span className="article__divider">/</span>
              <span className="article__date">{formatDate(doc.node.publication_date)}</span>
            </div>
          </div>
          <div className="article__inset">
            <div className="article__images">
              <Images
                imageData={images}
                extractPath="image"
                alt="My Company Image"
                classes={{
                  base: 'anim-image',
                  extend: 'article',
                  wrap: 'article__image',
                  class: ''
                }}
                width="526"
              />
            </div>
            <ArticleContent articleContent={doc.node.article_content} />
            <div className="anim-bottom-block">
              <div className="article-navi">
                <Link className="article-navi__link" to="/news">
                  <span className="article-navi__full">All</span> news
                  <span className="article-navi__link-arrow article-navi__link-arrow--reverse"></span>
                </Link>
                {doc.node.link_between_articles && (
                  <Link
                    className="article-navi__link"
                    to={linkResolver(doc.node.link_between_articles._meta)}
                  >
                    <span className="article-navi__link-arrow"></span>
                    Next <span className="article-navi__full">article</span>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ArticleTemplate($uid: String) {
    prismic {
      allNews_articles(uid: $uid) {
        edges {
          node {
            _meta {
              uid
            }
            author
            title
            publication_date
            category
            article_content {
              text_body
              image_body
            }
            publication_date
            link {
              ... on PRISMIC_News_article {
                _meta {
                  uid
                  type
                }
              }
            }
            link_between_articles {
              ... on PRISMIC_News_article {
                _meta {
                  uid
                  type
                }
              }
            }
            seo {
              page_title
              page_description
              page_og_image
              canonical_tag {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default ArticleTemplate;
